<template>
  <div class="shop-reviews">
    <div class="row mb-2">
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group :label="$t('From Date')">
          <b-form-datepicker
            v-model="dataInfo.fromDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group :label="$t('To Date')">
          <b-form-datepicker
            v-model="dataInfo.toDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group :label="$t('Rating')">
          <b-form-select
            v-model="dataInfo.rate"
            :options="rateOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="my-auto"
      >
        <b-button
          variant="primary"
          @click="getStoreReview"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
    </div>
    <no-data-available
      v-if="!optionsLoaded.reviews"
      :type="'reviews'"
    />
    <div
      v-if="optionsLoaded.reviews"
      class="wrap-reviews-info justify-content-center"
    >
      <div class="row">
        <b-card
          class="w-100 col-md-7"
          style="margin: 0 15px"
        >
          <div
            v-if="storeReviews"
            class="col-md-12 store-shadow wrap-store-review"
          >
            <h3 class="mt-2">
              {{ $t('All reviews') }}
            </h3>
            <no-data-available
              v-if="storeReviews.length === 0"
              :type="'reviews'"
            />
            <div
              v-for="(item, index) in storeReviews"
              :key="index"
              class="row reviews pt-2 pb-0"
              :class="{ 'border-bottom': index < storeReviews.length }"
            >
              <div class="col-md-9">
                <b-media class="pb-50 pt-50 align-items-center">
                  <template #aside>
                    <b-avatar />
                  </template>
                  <h6 class="mb-0">
                    {{ item.user ? `${item.user.first_name} ${item.user.last_name}` : '' }}
                    <span
                      v-if="item.order"
                      class="ml-1 font-weight-bolder"
                    >
                      ({{ $t('Order No: ') }} {{ item.order.store_order_invoice_id }})
                    </span>
                  </h6>
                  <p class="small mb-0">
                    {{ $t('Comment on:') }} {{ formatDate(item.created_at) }}
                  </p>
                </b-media>
                <p class="mt-2">
                  {{ item.review }}
                </p>
              </div>
              <div class="col-md-3 d-flex flex-wrap align-items-center justify-content-center">
                <div>
                  <h2 class="text-center">
                    {{ item.rate }}
                  </h2>
                  <div class="mb-0">
                    <ul class="unstyled-list list-inline">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item"
                        :class="{ 'ml-25': star - 1 }"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="16"
                          :class="[{ 'fill-current': star <= item.rate }, star <= item.rate ? 'theme-color' : 'text-muted']"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="item.review_response"
                class="col-md-9"
              >
                <div class="ml-5">
                  <b-media class="pb-50 pt-50 align-items-center">
                    <template #aside>
                      <b-avatar />
                    </template>
                    <h6 class="mb-0">
                      {{ item.store ? `${item.store.store_name}` : '' }}
                    </h6>
                    <p class="small mb-0">
                      {{ $t('Comment on:') }} {{ formatDate(item.review_response.created_at) }}
                    </p>
                  </b-media>
                  <p class="mt-2">
                    {{ item.review_response.review }}
                  </p>
                </div>
              </div>
              <div
                v-if="item.review_response"
                class="col-md-3 d-flex flex-wrap align-items-center justify-content-center"
              >
                <div>
                  <h2 class="text-center">
                    {{ item.review_response.rate }}
                  </h2>
                  <div class="mb-0">
                    <ul class="unstyled-list list-inline">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item"
                        :class="{ 'ml-25': star - 1 }"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="16"
                          :class="[{ 'fill-current': star <= item.review_response.rate }, star <= item.review_response.rate ? 'theme-color' : 'text-muted']"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <b-pagination
              v-if="entireReview"
              v-model="currentPage"
              class="justify-content-center mt-2"
              :total-rows="entireReview.total"
              :per-page="10"
              @page-click="userPageChanged"
            />
          </div>
        </b-card>
        <b-card
          v-if="lastReview"
          class="w-100 col-md-4"
          style="margin: 0 10px"
        >
          <div
            v-if="lastReview"
            class="col-md-12 store-shadow px-2 py-2 store-left-margin"
          >
            <div class="recent-reviews">
              <div class="d-flex justify-content-between align-items-center">
                <h2>{{ $t('Newest') }}</h2>
                <div class="wrap-next-prev">
                  <feather-icon
                    class="cursor-pointer"
                    icon="ChevronLeftIcon"
                    @click="prevReview"
                  />
                  <feather-icon
                    class="cursor-pointer"
                    icon="ChevronRightIcon"
                    @click="nextReview"
                  />
                </div>
              </div>
              <p class="mt-2">
                “{{ lastReview.review }}”
              </p>
              <b-media class="pb-50 pt-50 mt-2 align-items-center">
                <template #aside>
                  <b-avatar />
                </template>
                <h6>{{ lastReview.user ? `${lastReview.user.first_name} ${lastReview.user.last_name}` : '' }}</h6>
              </b-media>
              <div class="col-md-12 d-flex align-items-center justify-content-center latest-review-wrapper">
                <div class="w-100 d-flex align-items-center">
                  <h2 class="text-center mb-0 mr-1 text-white">
                    {{ lastReview.rate }}
                  </h2>
                  <div class="mb-0">
                    <ul class="unstyled-list list-inline mb-0">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item"
                        :class="{ 'ml-25': star - 1 }"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="16"
                          :class="[{ 'fill-current': star <= lastReview.rate }, star <= lastReview.rate ? 'theme-color' : 'text-muted']"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BMedia, BAvatar, BCard, BCol, BPagination, BFormGroup, BFormDatepicker, BButton, BFormSelect } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import moment from 'moment'
import NoDataAvailable from '../users/shared/NoDataAvailable.vue'
import { useStoreUi } from './useStore'

export default {
  components: {
    NoDataAvailable,
    BMedia,
    BAvatar,
    BCard,
    BPagination,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BCol,
    BFormSelect,
  },
  data() {
    return {
      storeReviews: null,
      optionsLoaded: {
        reviews: false,
      },
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      currentPage: 1,
      entireReview: null,
      reviewIndex: 0,
    }
  },
  computed: {
    lastReview() {
      if (!this.storeReviews) return null
      return this.storeReviews[this.reviewIndex]
    },
    rateOptions() {
      return [
        ...[
          {
            text: 'All',
            value: '',
          },
        ],
        ...[1, 2, 3, 4, 5].map(x => ({
          text: `${x} star`,
          value: x,
        })),
      ]
    },
  },
  mounted() {
    this.getStoreReview()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    prevReview() {
      if (!this.storeReviews) return null
      if (this.reviewIndex > 0) this.reviewIndex -= 1
      return null
    },
    userPageChanged() {
      showLoader()
      setTimeout(() => {
        this.getStoreReview()
      }, 250)
    },
    nextReview() {
      if (!this.storeReviews) return null
      if (this.reviewIndex < this.storeReviews.length - 1) this.reviewIndex += 1
      return null
    },
    getStoreReview() {
      const { shopReview } = useStoreUi()
      showLoader()
      const dataPayload = ['limit=10', `page=${this.currentPage}`]
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.rate) {
        dataPayload.push(`rate=${this.dataInfo.rate}`)
      }
      shopReview(`?${dataPayload.join('&')}`)
        .then(({ data }) => {
          this.entireReview = data.responseData
          this.storeReviews = data.responseData.data
          if (this.storeReviews && this.storeReviews && this.storeReviews.length > 0) {
            this.optionsLoaded.reviews = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/store-details.scss';
</style>

<style lang="scss" scoped>
.wrap-store-review .reviews:last-child {
  border-bottom: 0 !important;
}
</style>
